import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

class GoogleCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      // Ajoutez d'autres états nécessaires ici
    };
  }

  componentDidMount() {
    // Utilisez la configuration du calendrier ici
    const { apiKey, calendarId } = this.props;
    const currentDate = new Date();
    const currentDateTime = currentDate.toISOString();

    // Effectuez des appels à l'API Google Calendar ou toute autre logique nécessaire ici
    // Pour récupérer les événements, vous pouvez utiliser la bibliothèque Google Calendar API ou Axios, par exemple

    // Exemple fictif d'appel à une API (vous devrez utiliser la bibliothèque appropriée)
    fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?orderBy=startTime&singleEvents=true&key=${apiKey}&timeMin=${currentDateTime}&maxResults=4`)
      .then(response => response.json())
      .then(data => {
        this.setState({ events: data.items });
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des événements :', error);
      });
  }

  formatDateTime(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const formattedDate = format(dateObject, 'dd.MM', { locale: fr });
    const formattedTime = format(dateObject, 'HH:mm', { locale: fr });

    // Utilisez un fragment pour englober plusieurs éléments sans ajouter de nœud supplémentaire au DOM
    return (
        <span className="day">{formattedDate}</span>
    );
  }

  formatDateTime(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const formattedDate = format(dateObject, 'dd.MM', { locale: fr });
    const formattedTime = format(dateObject, 'HH:mm', { locale: fr });

    // Utilisez un fragment pour englober plusieurs éléments sans ajouter de nœud supplémentaire au DOM
    return (
        <span className="day">{formattedDate}</span>
    );
  }

  formatHourTime(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const formattedDate = format(dateObject, 'dd.MM', { locale: fr });
    const formattedTime = format(dateObject, 'HH', { locale: fr });

    // Utilisez un fragment pour englober plusieurs éléments sans ajouter de nœud supplémentaire au DOM
    return (
        <span className="hour">{formattedTime}</span>
    );
  }

  extractCityFromAddress(address) {
    // Utilisation d'une expression régulière pour extraire le code postal et la ville
    const regex = /\d{5}\s+(.+),/;
    const match = address.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return 'Paname';
    }
  }

  render() {
    const { events } = this.state;

    return (
      <section className='centered-div'>
        <h2>Prochaines Dates</h2>
        <table>
          <tbody>
            {events.map(event => (
              <tr key={event.id}>
                <td>{this.formatDateTime(event.start.dateTime)}</td>
                <td><a href={event.description || ''}><span className='comedyclub'>{event.summary}</span></a><br></br><b>{this.extractCityFromAddress(event.location)}</b> {this.formatHourTime(event.start.dateTime)}h</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  }
}

export default GoogleCalendar;
