import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import GoogleCalendar from './GoogleCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          SAMI
        </h1>
        {/* <FontAwesomeIcon icon="coffee" /> */}
        <div className="social">
            <a className="instagram" target="_blank" href="https://instagram.com/samilaaroussi"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
            <a className="twitter" target="_blank" href="http://twitter.com/slt"> <FontAwesomeIcon icon="fa-brands fa-x-twitter" /></a>
            <a className="tiktok" target="_blank" href="http://tiktok.com/@sami.laaroussi"><FontAwesomeIcon icon="fa-brands fa-tiktok" /></a>
            <a className="youtube" target="_blank" href="http://youtube.com/@samilaaroussitribek"><FontAwesomeIcon icon="fa-brands fa-youtube" /></a>
        </div>
      </header>
  
        <GoogleCalendar
          apiKey="AIzaSyA8iE-4Qaj1G_rplY4zwaKWvbAxTofiaIk"
          calendarId="293b2686396b93a66047f3117ee419bd84eaed86503af98ff0fd9f383c6e81da@group.calendar.google.com"/>
      <div className="background-container">
        <div className="gradient"></div>
        <div className="portrait"></div>
      </div>
    </div>
  );
}

export default App;



